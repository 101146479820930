import React from 'react'
import {graphql, StaticQuery} from 'gatsby'

import Card from '@material/react-card'

function CertificationsGrid() {
  return (
    <StaticQuery
      query={certificationsQuery}
      render={data => {
        const whatwedo = data.allMarkdownRemark.edges
        return (
          <section className="page-main__section">
            <div className="wedo__container">
              {whatwedo.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                return (
                    <Card
                      className="mdc-card--clickable mucsafe-blog-card"
                      key={node.fields.slug}
                    >
                        <div>
                          <h3>{title}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                node.frontmatter.description || node.excerpt,
                            }}
                          />
                        </div>
                    </Card>
                )
              })}
            </div>
          </section>
        )
      }}
    />
  )
}

const certificationsQuery = graphql`
  query CertificationsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/certifications/" } }
      sort: { fields: [frontmatter___title], order: DESC }
    ) {
      edges {
        node {
          excerpt(
          format: HTML
          pruneLength: 2000
          truncate: false
          )
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default CertificationsGrid
