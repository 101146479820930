import React from 'react'
import {graphql} from 'gatsby'

import SEO from '../components/SEO/SEO'
import CenteredLayout from '../components/CenteredLayout/CenteredLayout';
import CertificationsGrid from '../components/CertificationsGrid/CertificationsGrid';

class CertificationsPage extends React.Component {
  render() {
      const { data } = this.props
      const siteTitle = data.site.siteMetadata.title
    return (
          <CenteredLayout location={this.props.location} title={siteTitle}>
              <SEO title="Certifications" />
              <h1>
                  Zertifizerungsinformationen
              </h1>
              <CertificationsGrid />

          </CenteredLayout>
    )
  }
}

export default CertificationsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
